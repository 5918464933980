// 获取图表元素
var tableChart = document.getElementById('tableChart');
var eventFlow = document.getElementById('eventFlow');

// 隐藏swiper
document.querySelector('.swiper.mySwiper').style.display = 'none';
// document.querySelector('.swiper.mySwiper').style.display = 'block';

// 添加双击事件监听器
tableChart.addEventListener('dblclick', toggleFullScreen.bind(null, tableChart));
eventFlow.addEventListener('dblclick', toggleFullScreen.bind(null, eventFlow));


document.getElementById('searchButton').addEventListener('click', function () {
    var name = document.getElementById('nameInput').value;
    if (name) {
        fetch('https://hello2024.emdoor.tech/getSeat', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name: name })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {

                    showToast('查询成功', 1500)
                    if (data.data.length === 1) {
                        console.log(data.data[0]);
                        document.getElementById('seatInfo').innerHTML =
                            `<div style="margin-bottom: 5px; color: blue">
                        <br>尊敬的 ${data.data[0].姓名}<br><br>您的桌位号是: <strong style="background: linear-gradient(to right, red, darkorange); -webkit-background-clip: text; color: transparent; font-size: larger;">${data.data[0].桌号}</strong><br></div>`;

                        //获取签到状态，如果已经签到，隐藏签到按钮，如果没有签到，显示签到按钮，
                        if (data.data[0].签到状态 === '是') {
                            document.getElementById('checkinButton').style.display = 'none';
                            // showToast('您已签到');
                        } else {
                            document.getElementById('checkinButton').style.display = 'block';
                            showToast('您还没有签到呢,请签到', 2000);
                        }

                    } else {
                        let infoHTML = `<div style="margin-bottom: 5px; color: blue">尊敬的来宾，您有多个查询信息，请对号入座</div>`;
                        data.data.forEach(person => {
                            // 将每个人的信息追加到infoHTML字符串
                            infoHTML += `<div style="margin-bottom: 5px;">姓名: ${person.姓名}, 桌号: ${person.桌号}</div>`;
                        });
                        // 将累积的信息设置为seatInfo的innerHTML
                        document.getElementById('seatInfo').innerHTML = infoHTML;
                        document.getElementById('checkinButton').style.display = 'none';
                        // showToast('记得签到哦');
                    }

                } else {
                    alert(data.message);
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    } else {
        alert('请输入姓名');
    }
});


// 签到按钮点击事件
document.getElementById('checkinButton').addEventListener('click', function () {
    // requestCheckIn();
    getLocation();
});


function showToast(message, time = 1000) {
    var toast = document.getElementById("toast");
    toast.innerHTML = message;
    toast.className = "show";
    setTimeout(function () { toast.className = toast.className.replace("show", ""); }, time);
}




setInterval(function () {

    var elements = document.getElementsByClassName('animated-text');
    for (var i = 0; i < elements.length; i++) {
        var top = Math.random() * 20 - 10; // 随机生成-10到10之间的数
        elements[i].style.top = top + 'px';
    }
}, 300); // 每300毫秒更新一次位置




function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
}


function Fireworks() {
    var duration = 3 * 1000;
    var animationEnd = Date.now() + duration;
    var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };


    var interval = setInterval(function () {
        var timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
            return clearInterval(interval);
        }

        var particleCount = 50 * (timeLeft / duration);
        // since particles fall down, start a bit higher than random
        confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } });
        confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } });
    }, 250);
}



function fire(particleRatio, opts) {
    confetti({
        ...{
            origin: { y: 0.7 }
        },
        ...opts,
        particleCount: Math.floor(200 * particleRatio)
    });
}

function Realistic() {

    fire(0.25, {
        spread: 26,
        startVelocity: 55,
    });
    fire(0.2, {
        spread: 60,
    });
    fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8
    });
    fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2
    });
    fire(0.1, {
        spread: 120,
        startVelocity: 45,
    });
}



function SchoolPride() {
    var end = Date.now() + (3 * 1000);

    // go Buckeyes!
    var colors = ['#bb0000', '#ffffff'];

    (function frame() {
        confetti({
            particleCount: 2,
            angle: 60,
            spread: 55,
            origin: { x: 0 },
            colors: colors
        });
        confetti({
            particleCount: 2,
            angle: 120,
            spread: 55,
            origin: { x: 1 },
            colors: colors
        });

        if (Date.now() < end) {
            requestAnimationFrame(frame);
        }
    }());
}


function CustomShapes() {
    var pumpkin = confetti.shapeFromPath({
        path: 'M449.4 142c-5 0-10 .3-15 1a183 183 0 0 0-66.9-19.1V87.5a17.5 17.5 0 1 0-35 0v36.4a183 183 0 0 0-67 19c-4.9-.6-9.9-1-14.8-1C170.3 142 105 219.6 105 315s65.3 173 145.7 173c5 0 10-.3 14.8-1a184.7 184.7 0 0 0 169 0c4.9.7 9.9 1 14.9 1 80.3 0 145.6-77.6 145.6-173s-65.3-173-145.7-173zm-220 138 27.4-40.4a11.6 11.6 0 0 1 16.4-2.7l54.7 40.3a11.3 11.3 0 0 1-7 20.3H239a11.3 11.3 0 0 1-9.6-17.5zM444 383.8l-43.7 17.5a17.7 17.7 0 0 1-13 0l-37.3-15-37.2 15a17.8 17.8 0 0 1-13 0L256 383.8a17.5 17.5 0 0 1 13-32.6l37.3 15 37.2-15c4.2-1.6 8.8-1.6 13 0l37.3 15 37.2-15a17.5 17.5 0 0 1 13 32.6zm17-86.3h-82a11.3 11.3 0 0 1-6.9-20.4l54.7-40.3a11.6 11.6 0 0 1 16.4 2.8l27.4 40.4a11.3 11.3 0 0 1-9.6 17.5z',
        matrix: [0.020491803278688523, 0, 0, 0.020491803278688523, -7.172131147540983, -5.9016393442622945]
    });
    // tree shape from https://thenounproject.com/icon/pine-tree-1471679/
    var tree = confetti.shapeFromPath({
        path: 'M120 240c-41,14 -91,18 -120,1 29,-10 57,-22 81,-40 -18,2 -37,3 -55,-3 25,-14 48,-30 66,-51 -11,5 -26,8 -45,7 20,-14 40,-30 57,-49 -13,1 -26,2 -38,-1 18,-11 35,-25 51,-43 -13,3 -24,5 -35,6 21,-19 40,-41 53,-67 14,26 32,48 54,67 -11,-1 -23,-3 -35,-6 15,18 32,32 51,43 -13,3 -26,2 -38,1 17,19 36,35 56,49 -19,1 -33,-2 -45,-7 19,21 42,37 67,51 -19,6 -37,5 -56,3 25,18 53,30 82,40 -30,17 -79,13 -120,-1l0 41 -31 0 0 -41z',
        matrix: [0.03597122302158273, 0, 0, 0.03597122302158273, -4.856115107913669, -5.071942446043165]
    });
    // heart shape from https://thenounproject.com/icon/heart-1545381/
    var heart = confetti.shapeFromPath({
        path: 'M167 72c19,-38 37,-56 75,-56 42,0 76,33 76,75 0,76 -76,151 -151,227 -76,-76 -151,-151 -151,-227 0,-42 33,-75 75,-75 38,0 57,18 76,56z',
        matrix: [0.03333333333333333, 0, 0, 0.03333333333333333, -5.566666666666666, -5.533333333333333]
    });

    var defaults = {
        scalar: 2,
        spread: 180,
        particleCount: 30,
        origin: { y: -0.1 },
        startVelocity: -35
    };

    confetti({
        ...defaults,
        shapes: [pumpkin],
        colors: ['#ff9a00', '#ff7400', '#ff4d00']
    });
    confetti({
        ...defaults,
        shapes: [tree],
        colors: ['#8d960f', '#be0f10', '#445404']
    });
    confetti({
        ...defaults,
        shapes: [heart],
        colors: ['#f93963', '#a10864', '#ee0b93']
    });
}


window.onload = function () {
    setTimeout(function () {
        var animations = [Fireworks, Realistic, SchoolPride, CustomShapes];
        var randomAnimation = animations[Math.floor(Math.random() * animations.length)];
        randomAnimation();
    }, 500);

    /*
    fetch('/api/images')
        .then(response => response.json())
        .then(images => {
            var swiperWrapper = document.getElementById('swiper-wrapper');

            if (images.length === 0) {
                // var slide = document.createElement('div');
                // slide.className = 'swiper-slide';
                // var img = document.createElement('img');
                // img.src = 'assets/images/empty.png';
                // slide.appendChild(img);
                // swiperWrapper.appendChild(slide);
                return;
            }

            images.forEach(function (image) {
                var slide = document.createElement('div');
                slide.className = 'swiper-slide';

                var img = document.createElement('img');
                img.src = 'assets/images/' + image;

                slide.appendChild(img);
                swiperWrapper.appendChild(slide);
            });

            var swiper = new Swiper(".mySwiper", {
                grabCursor: true,
                effect: "creative",
                loop: true,
                // autoplay: {
                //     delay: 2500,
                //     disableOnInteraction: false,
                // },
                // zoom: true,
                freeMode: true,
                creativeEffect: {
                    prev: {
                        shadow: true,
                        translate: ["-125%", 0, -800],
                        rotate: [0, 0, -90],
                    },
                    next: {
                        shadow: true,
                        translate: ["125%", 0, -800],
                        rotate: [0, 0, 90],
                    },
                },
                pagination: {
                    el: ".swiper-pagination",
                    dynamicBullets: true,
                    clickable: true,
                },
            });
        });
        */
};




// 切换全屏函数
function toggleFullScreen(element) {
    if (!document.fullscreenElement) {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) { // Firefox
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) { // Chrome, Safari and Opera
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { // IE/Edge
            element.msRequestFullscreen();
        }
    } else {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { // Firefox
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { // IE/Edge
            document.msExitFullscreen();
        }
    }
}


function getLocation() {
    if (navigator.geolocation) {
        console.log('开始获取位置');
        showLoadingCircle();
        navigator.geolocation.getCurrentPosition(checkPosition, showError);
    } else {
        console.log('该浏览器不支持获取定位！');
        document.getElementById("seatInfo").innerHTML = "[ERROR]该浏览器不支持获取定位！.";
        showToast('该浏览器不支持获取定位！', 2000);
    }
}





function checkPosition(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    console.log(latitude, longitude);
    // document.getElementById("seatInfo").innerHTML = "当前位置经纬度：" + latitude + ", " + longitude + "";

    // 坪山燕子湖国际会展中心的经纬度 114.377563 ,22.697791
    const expectedLatitude = 22.69;
    const expectedLongitude = 114.37;

    //深业御园的经纬度 114.40,22.74
    // const expectedLongitude = 114.40;
    // const expectedLatitude = 22.74;

    const threshold = 0.03; // 允许的误差范围

    if (Math.abs(latitude - expectedLatitude) < threshold &&
        Math.abs(longitude - expectedLongitude) < threshold) {
        document.getElementById("seatInfo").innerHTML = "您已进入指定区域，当前位置：" + latitude + ", " + longitude + "，正在签到...";
        // showToast('您已进入指定区域，正在签到...',2000);
        requestCheckIn();
    } else {
        document.getElementById("seatInfo").innerHTML = "签到失败，不在指定区域内 ，当前位置：" + latitude + ", " + longitude + "";
        showToast('签到失败，不在指定区域内。', 2000);
    }
    hideLoadingCircle();
}

//向服务器请求签到
function requestCheckIn() {
    var name = document.getElementById('nameInput').value;
    if (name) {
        //  fetch('http://hello2024.emdoor.tech/api/images')
        fetch('/api/checkin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name: name })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    document.getElementById('checkinButton').style.display = 'none';
                    document.getElementById("seatInfo").innerHTML = "签到成功";
                    showToast('签到成功', 2000);
                } else {
                    alert(data.message);
                }
            })
            .catch(error => {
                console.error('Error:', error);
                showToast(error.message);
            });
    } else {
        alert('请输入姓名');
    }
}

function showError(error) {
    switch (error.code) {
        case error.PERMISSION_DENIED:
            document.getElementById("seatInfo").innerHTML = "User denied the request for Geolocation."
            break;
        case error.POSITION_UNAVAILABLE:
            document.getElementById("seatInfo").innerHTML = "Location information is unavailable."
            break;
        case error.TIMEOUT:
            document.getElementById("seatInfo").innerHTML = "The request to get user location timed out."
            break;
        case error.UNKNOWN_ERROR:
            document.getElementById("seatInfo").innerHTML = "An unknown error occurred."
            break;
    }
}


function showLoadingCircle() {
    document.getElementById("seatInfo").innerHTML = "正在获取位置信息，请稍等...";
    document.getElementById("loadingCircle").style.display = "block";
}

function hideLoadingCircle() {
    document.getElementById("loadingCircle").style.display = "none";
}


document.addEventListener('DOMContentLoaded', function () {
    fetch('/api/visitStats')
        .then(response => {
            // 首先检查响应是否成功
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log('Received data:', data);
            // 使用从后端获取的数据更新页面
            document.getElementById('today-visits').textContent = data.todayVisits;
            document.getElementById('total-visits').textContent = data.totalVisits;
        })
        .catch(error => {
            console.error('Fetch error:', error);
            // 在这里处理错误，比如显示错误信息
            document.getElementById('today-visits').textContent = '加载失败';
            document.getElementById('total-visits').textContent = '加载失败';
        });
});